import { Navbar , Nav, Container,NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, } from 'react'
import './Navbar.css';
import logo from "./Assets/logo.png";



function Navigation() {
  
  const [show, setShow] = useState(false);
  const [button,setButton] = useState(true);


   

  const showButton = () =>{
    if(window.innerWidth <= 960){
      setButton(false);
      setNavbar(true);
    }
    else{
      setButton(true);
    }
  }
  
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }

const [navbar,setNavbar] = useState(false);

  const changeBackground = () =>{
    if(button){
    if(window.scrollY>=80){
      setNavbar(true);
    }
    else{
      setNavbar(false);
    }
  }
  else{
    setNavbar(true);
  }
  }
  useEffect(()=>{
    showButton();
  },[]);

  window.addEventListener('resize',showButton);

window.addEventListener('scroll',changeBackground);

return(
<Navbar style={{backgroundColor:'white',transition:'1000ms',}} collapseOnSelect expand="lg" variant="light" fixed='top'>
  <Navbar.Brand  href="/"><text style={{paddingLeft:30,fontSize:18,letterSpacing:2,fontWeight:'bold'}}>ETERNITY</text></Navbar.Brand>
  <Navbar.Toggle style={{marginRight:10}} aria-controls="responsie-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{paddingRight:50}}>

    <Nav   style={{marginTop:button?'':25,marginBottom:button?'':25}}>
    <Nav.Link className='navLink'  style={{marginLeft:30}} href="/">Home</Nav.Link>
    <Nav.Link className='navLink'  style={{marginLeft:30}} href="/products">Products</Nav.Link>
      <Nav.Link className='navLink'  style={{marginLeft:30}} href="/about">About Us</Nav.Link>
      <Nav.Link className='navLink' style={{marginLeft:30}}  eventKey={2} href="/contact">
        Contact Us
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
);
} 

export default Navigation;