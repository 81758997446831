

import React, { useState, useEffect, } from 'react'

import './About.css';

import p14 from "../Assets/p14.jpg";
import p5 from "../Assets/p5.png";
import Footer from '../Footer';

function About() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
   <div style={{backgroundColor:'#E1DAD0'}}>

   <div className='about'>
    <div style={{display:'flex',flexDirection:'column',paddingLeft:'10vw',paddingTop:50}}>
   <text style={{fontSize:button?20:18,fontWeight:600,width:button?'50vw':'75vw'}}>Our journey began in 2021 with a vision to redefine the standards of sophistication and functionality in interior spaces. Established with a passion for transforming spaces into timeless marvels, Eternity offers cutting-edge solutions in hardware and interior design.</text>
   <text style={{paddingTop:button?8:10,fontSize:18}}>Let's elevate your space.</text>
   </div>
  </div>
  <div style={{display:'flex',flexDirection:'row',backgroundColor:'white',padding:5,borderBottom:'1px solid grey',textOverflow:'clip',overflow:'hidden',fontSize:14,justifyContent:'center',fontFamily:'Cardo'}}>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>
  <text style={{paddingRight:100}}>Eternity</text>

  
  
  </div>
  <div style={{display:'flex',flexDirection:button?'column':'column',paddingTop:button?'30vh':'15vh',paddingBottom:button?'15vh':'5vh',paddingLeft:button?'30vw':'10vw',width:button?'60vw':'70vw'}}>
    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
      <div style={{height:2,width:30,backgroundColor:'black',marginTop:2}}></div>
      <text style={{fontSize:24,paddingLeft:10}}>European Quality</text>
      </div>
        <text style={{color:'gray',marginTop:10,}}>Our products embody the spirit of artistry, innovation, and a legacy of skilled workmanship that spans generations. We source premium quality products directly from Europe, renowned for their exceptional craftsmanship and materials.</text>
    </div>
    <div style={{display:'flex',flexDirection:button?'row':'column',alignItems:'center',marginBottom:button?'20vh':'15vw'}}>
      <div style={{display:'flex',width:button?'50vw':'',padding:button?'15vh':'10vw'}}>
      <img  style={{height:button?'80vh':'',width:button?'':'100%',}} src={p5}/>
      </div>
      <text style={{fontSize:22,color:'#394352',width:button?'30vw':'',margin:button?'4%':'10vw',paddingBottom:button?'':'15vw',borderBottom:button?'':'1px solid grey'}}>We understand the desire for a personal touch. That's why we offer <span style={{fontWeight:'bold'}}>customization options</span>, allowing you to tailor your chosen product to your unique style and preferences.</text>
    </div>
<div style={{display:'flex',flexDirection:'column',alignItems:'center',color:'#394352',textAlign:'center',marginBottom:button?'30vh':'15vh'}}>
  <text style={{fontSize:12,letterSpacing:2}}>MISSION</text>
  <text style={{fontWeight:500,fontSize:button?24:18,padding:10}} >Setting new standards of excellence</text>
  <text style={{width:button?'50vw':'75vw'}}>
At Eternity, our mission is to be the vanguard of transformative design and cutting-edge hardware solutions in the realm of interior spaces. We are commitment to delivering excellence, innovation, and enduring quality in every project we undertake.</text>
</div>
<img  style={{height:button?'':'25vh',width:'100%'}} src={p14}/>
    <div style={{display:'flex',flexDirection:'row',backgroundColor:'#E4E4E4',height:button?'90vh':'60vh',alignItems:'center',padding:button?'15vh':'4vh'}}>
      <div style={{display:'flex',flexDirection:'column',marginLeft:button?'10vw':'',fontSize:18}}>
      <text style={{fontWeight:600,fontSize:24}}>Our Experience</text>
      <text style={{width:button?'30vw':'75vw',paddingTop:15,paddingBottom:15}}>We invite you on a journey where your experience is as remarkable as the spaces we create together.</text>
      <text style={{}}>+100 Projects</text>
      <text style={{}}>+50 Employees</text>
      <text style={{}}>5 Stores</text>
      </div>
    
    </div>
<Footer/>
   </div>
  );
}

export default About;