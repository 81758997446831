import React, { useState, useEffect, } from 'react'
import '../App.css';
import p15 from "../Assets/p15.jpg";
import p16 from "../Assets/p16.jpg";
import p1 from "../Assets/p1.png";
import p2 from "../Assets/p2.png";
import p3 from "../Assets/p3.png";
import p4 from "../Assets/p4.png";
import p5 from "../Assets/p5.png";
import p6 from "../Assets/p6.png";
import p7 from "../Assets/p7.png";
import p8 from "../Assets/p8.png";
import p9 from "../Assets/p9.png";
import p10 from "../Assets/p10.png";
import p11 from "../Assets/p11.png";
import p12 from "../Assets/p12.png";
import p17 from "../Assets/p17.jpg";
import p18 from "../Assets/p18.jpg";
import p19 from "../Assets/p19.jpg";


import n1 from "../Assets/n1.png";
import n2 from "../Assets/n2.png";
import n3 from "../Assets/n3.png";
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';

import Aos from 'aos';
import 'aos/dist/aos.css'; 


const Projects = props => {


    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
  Aos.init({duration:1000});
},[]);

  window.addEventListener('resize',showButton);


  return (
    <div>
       <div style={{display:'flex',flexDirection:button?'row':'column',alignItems:'center',marginBottom:button?'20vh':'10vh'}}>
      <div style={{display:'flex',width:button?'50vw':'',paddingTop:'20vh',paddingLeft:button?'10vh':''}}>
      <img  style={{height:button?'70vh':'',width:button?'':'100%',}} src={p15}/>
      </div>
      <div style={{display:'flex',flexDirection:'column',textAlign:button?'':'center'}}>
      <text style={{fontSize:24,width:button?'25vw':'',fontWeight:600,paddingTop:button?'15vh':'10vh'}}>Explore our collection</text>
      <text style={{width:button?'20vw':'80vw',paddingTop:'2vh'}}>Discover how the artistry of details can transform your space into an enduring masterpiece.</text>
      </div>
    </div>

<div style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',marginBottom:button?'20vh':'10vh'}}>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25,width:button?'30vw':'',display:'flex',flexDirection:'column',alignItems:'center'}}>
        <img style={{height:80,width:80,marginBottom:10}} src={n1}></img>
      <text style={{fontWeight:'500'}}>Best Quality</text>
        <text style={{fontSize:14,color:'grey',padding:15}}>Eternity sources only the finest raw materials to ensure each product is of excellent quality.</text>
      </div>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25,width:button?'30vw':'',display:'flex',flexDirection:'column',alignItems:'center'}}>
        <img style={{height:80,width:80,marginBottom:10}} src={n2}></img>
      <text style={{fontWeight:'500'}}>Modern Design</text>
        <text style={{fontSize:14,color:'grey',padding:15}}>We thrive on pushing the boundaries of design, constantly evolving to stay ahead of trends.</text>
      </div>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25,width:button?'30vw':'',display:'flex',flexDirection:'column',alignItems:'center'}}>
        <img style={{height:80,width:80,marginBottom:10}} src={n3}></img>
      <text style={{fontWeight:'500'}}>High Performance</text>
        <text style={{fontSize:14,color:'grey',padding:15}}>Our designs seamlessly integrate functionality and durability, ensuring best performance.</text>
      </div>
    </div>
   

    <div style={{display:'flex',flexDirection:button?'row':'column',alignItems:'center',marginBottom:button?'20vh':'10vh',backgroundColor:'#E1DAD0'}}>
      <div style={{display:'flex',width:button?'50vw':'100vw',padding:button?'20vh':''}}>
      <img  style={{height:button?'70vh':'50vh',width:button?'':'100%',border:'1px solid black'}} src={p16}/>
      </div>
      <div style={{display:'flex',flexDirection:'column'}}>
      <text style={{width:button?'30vw':'85vw',paddingBottom:button?'':'15vw',paddingTop:button?'2vh':'10vw',fontSize:button?16:14}}>Our collection encompasses precision-engineered hinges, locks, and accessories that not only enhance security but also serve as distinctive design elements, setting a tone of sophistication.</text>
      </div>
    </div>
    <div style={{padding:button?'3vw':''}}>
      <div style={{display:'flex',flexDirection:'column',marginBottom:25,alignItems:'center',paddingBottom:button?100:40,textAlign:'center'}}>
      <text style={{fontSize:24,paddingBottom:15}}>Our Products</text>
      <text style={{fontSize:14,width:button?'35vw':'80vw',color:'grey',letterSpacing:0.5}}>Designed to be versatile and customizable, our offerings allow you to create a space that perfectly reflects your style.</text>
      </div>
 
      <div style={{display:'flex',flexDirection:'column',alignItems:button?'center':''}}>
      <div style={{display:'flex',flexDirection:button?'row':'column'}}> 
        <div style={{marginRight:button?'3vw':'',marginLeft:button?'':'5vw'}}>
        <img className='img' src={p1} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Slim Frame Wardrobe Door</text>
       <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>An exclusive product designed to turn your closet system into a smooth and flexible aesthetic system.</text>
        </div>
        </div>
       
        <div style={{marginRight:button?'3vw':'',marginLeft:button?'':'5vw'}}>
        <img className='img' src={p2} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Sliding Door System</text>
        <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>An essential when it comes to making your space aesthetic and minimalist, while also keeping its functionality intact.</text>
        </div>
        </div>
        <div style={{marginLeft:button?'':'5vw'}}>
        <img className='img' src={p3} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{letterSpacing:1,fontWeight:600}}>Walk-In Closet</text>
        <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>An advanced design that offers great flexibility in dimensions, materials & finishes.</text>
        </div>
        </div>
      
      </div>
    
      <div style={{display:'flex',flexDirection:button?'row':'column'}}> 
      <div style={{marginRight:button?'3vw':'',marginLeft:button?'':'5vw'}}>
        <img className='img' src={p4} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Swing Door System</text>
        <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>Crafted in aluminum frame, it is lightweight and features a stylish, clean and modern look.</text>
        </div>
        </div>
       
        <div style={{marginRight:button?'3vw':'',marginLeft:button?'':'5vw'}}>
        <img className='img' src={p5} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Pivot Door System</text>
        <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>An ideal solution to make a space look more spacious and organized.</text>
        </div>
        </div>
        <div style={{marginLeft:button?'':'5vw'}}>
        <img className='img' src={p18} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Wardrobe Shelving System</text>
        <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>A game-changer for keeping your wardrobe tidy and well-organized.</text>
        </div>
        </div>
      
      </div>
    
      <div style={{display:'flex',flexDirection:button?'row':'column'}}> 
      <div style={{marginRight:button?'3vw':'',marginLeft:button?'':'5vw'}}>
        <img className='img' src={p17} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Smart Cube System</text>
    <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>Modern storage solution with adaptability, flexibility, connectivity and sophastication.</text>
        </div>
        </div>
       
        <div style={{marginRight:button?'3vw':'',marginLeft:button?'':'5vw'}}>
        <img className='img' src={p8} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Heave Duty Pivot Door</text>
        <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>Crafted from premium materials, our pivot system guarantees durability and reliability, even under heavy usage.</text>
        </div>
        </div>
        <div style={{marginLeft:button?'':'5vw'}}>
        <img className='img' src={p19} style={{width:button?'25vw':'90vw'}}/>
        <div style={{display:'flex',flexDirection:'column',width:button?'25vw':'80vw',marginTop:20,fontSize:14,marginBottom:50}}>
        <text data-aos="fade-up"  style={{fontWeight:600}}>Shower Cubicle System</text>
        <text data-aos="fade-up"  style={{color:'grey',paddingTop:5,letterSpacing:0.5}}>A shower suite system with a sleek style door, crafted to optimise your bathroom space.</text>
        </div>
        </div>
      
      </div>

    <div style={{display:'flex',flexDirection:'column',marginTop:button?'20vh':'5vh',textAlign:'center',alignItems:'center',marginBottom:button?'':'5vh'}}>
      <text style={{fontSize:button?24:16,fontWeight:600}}>Make a visit to our store today!</text>
      <div style={{border:'1px solid black',width:120,margin:30,height:30,display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
      <a href="/contact" style={{fontSize:14,color:'black',textDecoration:'none'}}>Contact Us</a>
      </div>
    </div>

    </div>

   
    
    </div>
 
<Footer/>
   </div>
  );
}

export default Projects;