import React, { useState, useEffect, } from 'react'
import '../App.css';
import { BsArrowRight } from "react-icons/bs";

import bg5 from "../Assets/bg5.jpg";
import p1 from "../Assets/p1.png";
import p11 from "../Assets/p11.png";

import p20 from "../Assets/p20.jpg";
import p6 from "../Assets/p6.png";
import p7 from "../Assets/p7.png";
import p8 from "../Assets/p8.png";
import p9 from "../Assets/p9.png";
import p10 from "../Assets/p10.png";


import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';
function Home() {


    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
    <div style={{backgroundColor:'#EDE9E3',fontFamily:'raleway'}}>
   <div  className='bg' style={{display:'flex',flexDirection:'column'}}>   
      <div  style={{marginTop:'45vh',paddingLeft:'5vw',color:'white',textAlign:'center',display:'flex',flexDirection:'column'}}>
      <text style={{fontSize:50,lineHeight:button?'':1.2}}> Design your world</text>
      <text style={{fontFamily:'sans-serif',letterSpacing:2,paddingTop:button?5:10}}>WITH ETERNITY</text>
      <div className="arrow">
        <span></span>
        <span></span>
        <span></span>
    </div>
      </div>
    </div>

    <div style={{display:'flex',justifyContent:'center',flexDirection:'column',textAlign:'center',backgroundColor:'#394352',paddingBottom:'30vh',alignItems:'center',color:'#EDE9E3'}}>
        <text style={{fontSize:button?90:50,lineHeight:1,paddingTop:'30vh'}}>Elegant & <br></br>Engineered.</text>
      <text style={{fontSize:button?14:16,padding:20,fontFamily:'sans-serif',width:button?'30vw':'80vw'}}>A world where quality meets aesthetics, and where your spaces are defined by premium hardware.</text>
      <div style={{border:'1px solid white',width:120,margin:30,height:30,display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
      <a href="/about" style={{fontSize:14,color:'#EDE9E3',textDecoration:'none'}}>Know More</a>
      </div>
        </div>
        <div style={{paddingTop:button?125:75,textAlign:'center'}}>
<text style={{fontSize:18,}}>What we offer</text>
    </div>
      <div className={button?"container":'mobilecontain'}>
        <div>
      <img  src={p1}/>
      <text style={{fontSize:14,fontWeight:600}}>Furniture Systems</text>
      </div>
      <div>
      <img  src={p10}/>
      <text style={{fontSize:14,fontWeight:600}}>Door & Partition Systems</text>
      </div>
      <div>
      <img  src={p9}/>
      <text style={{fontSize:14,fontWeight:600}}>Shower Enclosures</text>
      </div>
      <div>
      <img  src={p20}/>
      <text style={{fontSize:14,fontWeight:600}}>Customization</text>
      </div>
   

      </div>
      <div style={{display:'flex',justifyContent:'center',paddingBottom:75}}>
      <div style={{border:'1px solid black',width:120,margin:30,height:30,display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
      <a href="/products" style={{fontSize:14,color:'black',textDecoration:'none'}}>Explore All</a>

      </div>
      </div>
      <div style={{display:'flex',flexDirection:button?'row':'column',backgroundColor:'white',alignItems:'center',}}>
      <div style={{display:'flex',width:button?'50vw':'',padding:button?'15vh':'10vw',paddingBottom:0}}>
      <img  style={{height:button?'70vh':'',width:button?'':'80vw',}} src={p11}/>
      </div> 
      <div style={{display:'flex',justifyContent:'center',flexDirection:'column',padding:button?'2vw':'10vw'}}>
        <text style={{fontSize:24}}>Passion for perfection</text>
      <text style={{fontSize:14,color:'#1d1c18',width:button?'30vw':'',paddingBottom:button?'':'15vw',lineHeight:1.8,paddingTop:5}}>Our passion for perfection drives every decision we make. From design conception to product delivery, we are unwavering in our commitment to delivering excellence.</text>
        </div>
    </div>
    


    <div style={{display:'flex',flexDirection:button?'row':'column',marginLeft:button?'15vw':'10vw',marginRight:button?'15vw':'10vw',marginBottom:'15vh',marginTop:'15vh'}}>
      <text style={{fontSize:22,width:button?600:''}}>Innovation</text>
        <text style={{color:'gray',marginLeft:button?'10vw':'',fontSize:14,paddingTop:button?'':15,lineHeight:1.8}}>Stay ahead of design trends with Eternity's commitment to modern aesthetics. Our collection evolves with the times, offering you the latest in contemporary design while retaining a timeless allure.</text>
    </div>

    <img src={bg5} style={{width:'100%'}}/>


    <div style={{display:'flex',flexDirection:'column',padding:button?'8vw':'10vw',backgroundColor:'white'}}>
      <text style={{fontSize:button?40:30,paddingBottom:button?50:30,fontWeight:'bold'}}>The core of <br></br>our philosophy</text>
        <text style={{fontSize:16,paddingLeft:button?'35vw':'10vw',paddingRight:button?'10vw':'',textAlign:'justify'}}>Our core philosophy is built on a foundation of unwavering commitment to quality, innovation, and customer satisfaction. We believe in setting the highest standards for excellence in every product we offer, ensuring that functionality seamlessly intertwines with aesthetics.</text>
 
    </div>
  
    <Footer/>
    </div>
  );
}

export default Home;