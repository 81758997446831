import './Footer.css';
import React, { useState, useEffect, } from 'react'
import log1 from "./Assets/logo.png";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {

    const [button,setButton] = useState(true);

    const showButton = () =>{
      if(window.innerWidth <= 960){
        setButton(false);
      }
      else{
        setButton(true);
      }
    }
    
    useEffect(()=>{
      showButton();
    },[]);
      window.addEventListener('resize',showButton);
    

  return (
    <div style={{display:'flex',padding:'8vw',flexDirection:'column',paddingBottom:0,backgroundColor:'white'}}>
      <div style={{display:'flex',flexDirection:'column',backgroundColor:'#394352',width:'100%',color:'#EDE9E3',padding:'8vw',textAlign:button?'':'center'}}>
    <text style={{fontSize:button?22:16,fontWeight:500}}>Inspired by our work? Learn more about us.</text>

    <div style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-between',borderTop:'1px solid #EDE9E350',borderBottom:'1px solid #EDE9E350',marginTop:button?'3vw':'6vw',paddingTop:button?'2vw':'6vw',paddingBottom:button?'2vw':'6vw',fontSize:14}}>
    <a href='/' style={{paddingBottom:button?'':15,color:'#EDE9E3',textDecoration:'none'}}>Home</a>
    <a href='/products' style={{paddingBottom:button?'':15,color:'#EDE9E3',textDecoration:'none'}}>Products</a>
    <a href='/about' style={{paddingBottom:button?'':15,color:'#EDE9E3',textDecoration:'none'}}>About Us</a>
    <a href='/contact' style={{paddingBottom:button?'':15,color:'#EDE9E3',textDecoration:'none'}}>Contact Us</a>
    <a href='https://www.instagram.com/eternityliving_/' target='blank' style={{paddingBottom:button?'':15,color:'#EDE9E3',textDecoration:'none'}}>Instagram</a>
    <text>Facebook</text>
    </div>

  </div>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize:14,padding:'3vw'}}>
  <text>
  Copyright © 2024 Eternity
  </text>
  <text>
    A Website by Cray
  </text>
  
  </div>  
  </div>
  );
};

export default Footer;