

import React, { useState, useEffect, } from 'react'
import Footer from '../Footer';
import p13 from "../Assets/p13.png";

import './About.css';

function Contact() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
   <div style={{backgroundColor:'#E1DAD0',fontFamily:'raleway'}}>
    <div style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-between',}}>
      <div style={{display:'flex',paddingLeft:button?'5vw':'',flexDirection:'column',alignItems:button?'':'center',textAlign:button?'':'center',paddingBottom:'10vh'}}>
      <text style={{paddingTop:button?'50vh':'15vh',fontSize:34,lineHeight:1.2,fontWeight:'bold'}}>Let's Work<br></br> Together</text>
      <div style={{borderTop:'2px solid black',width:75,marginTop:15}}></div>
      <text style={{width:button?'25vw':'70vw',marginTop:20,fontSize:button?'':14}}>We look forward to connecting with you and being a part of your journey to creating timeless spaces.</text>
      </div>
    <img style={{width:button?'45vw':'',height:button?'110vh':''}} src={p13}/>
    </div>
    <div style={{backgroundColor:'#E4E4E4',textAlign:'center',display:'flex',justifyContent:'center',flexDirection:'column'}}>

    <div style={{display:'flex',flexDirection:button?'row':'column',marginTop:button?'20vh':'10vh',alignItems:'center',justifyContent:'center',fontSize:14,marginBottom:button?'20vh':'10vh'}}>

      <div style={{display:'flex',flexDirection:'column',width:button?'30vw':'70vw',borderRight:button?'1px solid grey':'',paddingTop:20,paddingBottom:20,}}>
      <a href='tel:+918390909394'  style={{paddingBottom:button?2:5,color:'black',textDecoration:'none'}}>+91 8390909394</a>    
      <a href='tel:+918390909396' style={{paddingBottom:button?2:5,color:'black',textDecoration:'none'}} >+91 8390909396</a>    
      <a href="mailto:info@eternityliving.in"  style={{color:'black',textDecoration:'none'}}>info@eternityliving.in</a>
      </div>
      <div style={{display:'flex',flexDirection:'column',width:button?'30vw':'70vw',borderRight:button?'1px solid grey':'',paddingTop:20,paddingBottom:20}}>
      <text>Eternity Interior Solution</text>
      <text>Laxmi Woollen Mills, Next to Kohler Exp.</text>
      <text>centre, Mahalakshmi, Mumbai - 400011</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',width:button?'30vw':'70vw',paddingTop:20,paddingBottom:20}}>
      <a href='https://maps.app.goo.gl/vhQfppbQuWScJFEP8'  target="_blank" style={{paddingBottom:button?2:5,color:'black',textDecoration:'none'}}> Maps</a>
      <a href='https://www.instagram.com/eternityliving_/'  target="_blank" style={{paddingBottom:button?2:5,color:'black',textDecoration:'none'}}>Instagram</a>
      <text>Facebook</text>

      </div>
      </div>
      <div style={{display:'flex',flexDirection:'column',marginBottom:button?'20vh':'15vh',}}>
<text style={{fontWeight:'bold',color:'#394352',fontSize:20,marginBottom:25,}}>Our Exclusive Partners</text>
<text>Kismat, Pune</text>
<text>Kismat - Shastri Hall, Mumbai</text>
<text>Panchshil, Hyderabad</text>
<text>Panchshil, Jodhpur</text>
</div>
    </div>
<Footer/>
   </div>
  );
}

export default Contact;